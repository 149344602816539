import { css } from '@emotion/react';
import React, { memo, useState } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import IntradayTrendChart from '~/modules/trendChart';
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { Radio } from '@mantine/core';
import { SwitchSortButton } from './freeman_SidePane';
import { useSortSignalrDataStore } from '~/modules/symbolQuote/simple/useSortStore';
import { useSnapshot } from 'valtio';
import { freemanStore } from './component/VideoContnet';
const SymbolChart = memo(function SymbolChart(props) {
    return (<div css={css `
        display: grid;
        grid-template-rows: 26px 1fr;
        width: 100%;
        height: 100%;
        flex-shrink: 0;
        background-color: #1c1c1c;
        border-radius: 4px;
      `}>
      <div css={css `
          & > div {
            width: 100%;
            height: 26px;
            font-size: 12px;
          }
        `}>
        <TitleSymbolQuote.Simple symbol={props.symbol}/>
      </div>

      <div css={css `
          position: relative;
          width: 100%;
          height: 116px;
        `}>
        <div css={css `
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
          `}>
          <IntradayTrendChart symbol={props.symbol} ticksSize={11} ticksHeight={20} priceTicksMargin={-14} priceTicksSize={11}/>
        </div>
      </div>
    </div>);
});
export const Freeman_Row = memo(function Freeman_Row() {
    const [column, setColumn] = useState(4);
    const state = useSnapshot(freemanStore);
    const data = state.popularSymbols;
    return (<div css={classes.container}>
      <div css={classes.header}>
        <span css={css `
            height: 20px;
            font-size: 14px;
            font-weight: bold;
            background-color: #545454;
            border-radius: 4px;
            padding: 0 8px;
          `}>
          個股走勢圖
        </span>
        <SwitchSortButton sortStore={useSortSignalrDataStore} sortKey='amount'>
          成交金額
        </SwitchSortButton>
        <SwitchSortButton sortStore={useSortSignalrDataStore} sortKey='changePrecent'>
          漲跌幅
        </SwitchSortButton>
        <div css={css `
            height: 100%;
            display: flex;
            width: auto;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            margin-left: auto;
          `}>
          <Radio size='xs' label='3欄' onClick={() => setColumn(3)} checked={column === 3}/>
          <Radio size='xs' label='4欄' onClick={() => setColumn(4)} checked={column === 4}/>
          <Radio size='xs' label='5欄' onClick={() => setColumn(5)} checked={column === 5}/>
        </div>
      </div>
      <div css={css `
          ${classes.body};
          grid-template-columns: repeat(${column}, 1fr);
          gap: 6px;
        `}>
        {data.map(quote => {
            return (<SymbolChart key={quote.symbol} symbol={quote.symbol}/>);
        })}
      </div>
    </div>);
});
const classes = {
    container: css `
    ${fill_vertical_cross_center};
    background-color: #151515;
    padding: 4px;
    gap: 2px;
  `,
    header: css `
    ${fill_horizontal_cross_center};
    height: 28px;
  `,
    body: css `
    ${scrollbar2Css};
    display: grid;
    max-width: 100%;
  `,
};
