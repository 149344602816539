import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mantine/core';
import { memo, useEffect, useState } from 'react';
import { fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { useSnapshot } from 'valtio';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { edwin_store } from './edwin_store';
import { ScreenerContent } from './component/content/ScreenerContent';
import { WatchListContent } from './component/content/WatchListContent';
import { useVirtualAccountOrderPre } from '~/modules/virtualExchange/useVirtualExchangeResource';
import dayAPI from '~/utils/dayAPI';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { useSymbolWatchList } from '~/modules/WatchList/useSymbolWatchList';
import { edwinGroupName } from './component/WatchListGroupName';
import { ListChangeOverview } from './component/ListChangeOverview';
import { usePopularPick } from '~/modules/investment-consultant/stockPickTable/stockPickTable';
import { staticStore } from '../heineken_template/_private/staticStore';
import { RankingContent } from './component/content/RankingContent';
import { useBig3ConsecutiveResource, useEdwinResource, useTurnoverRateResource, } from '~/modules/screener/containers/useStockScreenerResource';
import { Big2Content } from './component/content/Big2Content';
import { TurnoverContent } from './component/content/TurnoverContent';
import { apirc } from '~/configs/apirc';
import { store } from '../heineken_template/_private/store';
const ModeButton = memo(function ModeButton(props) {
    const state = useSnapshot(edwin_store).mode;
    return (<Button variant='gradient' size='sm' gradient={state === props.mode
            ? { from: '#ecb04d', to: '#f4bd50', deg: 90 }
            : { from: '#404550', to: '#404550', deg: 90 }} css={css `
        color: ${state === props.mode ? '#252525' : ''};
        width: 100%;
        height: 28px;
        padding: 0;
      `} onClick={() => (edwin_store.mode = props.mode)}>
      {props.children}
    </Button>);
});
export const Edwin_Sidebar1 = memo(function Edwin_Sidebar1() {
    const charting = useSnapshot(store).charting;
    const state = useSnapshot(edwin_store);
    const intraday = useSnapshot(staticStore).tradedDate.intraday;
    const day0 = useSnapshot(staticStore).tradedDate.day0;
    const day1 = useSnapshot(staticStore).tradedDate.days[1];
    //選股--------------------------------------------------------
    const screenerData = useVirtualAccountOrderPre('edwin', 'B', {
        dateTime: dayAPI(state.screenerDate).format('YYYY-MM-DD'),
    })?.map(s => s.symbol) ?? [''];
    const perOrderSymbol = screenerData.filter((data, index, self) => {
        return self.indexOf(data) === index;
    });
    const realTimeSymbol = useEdwinResource({ date: intraday.format('YYYY-MM-DD') }).data ?? [];
    const screenerSymbol = state.screenerType === 'realTime' ? realTimeSymbol : perOrderSymbol;
    useEffect(() => {
        signalrStore2.addQuote(screenerSymbol);
        return () => {
            signalrStore2.removeQuote(screenerSymbol);
        };
    }, [JSON.stringify(screenerSymbol)]);
    //排行榜----------------------------------------------------------------
    const popularData = usePopularPick({
        date: day0.format('YYYY-MM-DD'),
        sort: 'value',
        limit: 50,
    });
    const popularSymbol = popularData.resultSymbol;
    useEffect(() => {
        signalrStore2.addQuote(popularSymbol);
        return () => {
            signalrStore2.removeQuote(popularSymbol);
        };
    }, [JSON.stringify(popularSymbol)]);
    //週轉率------------------------------------------------------
    const turnoverEmptyData = [
        {
            date: '',
            symbol: '',
            open: 0,
            high: 0,
            low: 0,
            close: 0,
            volume: 0,
            avg_turnover_rate: 0,
            turnover_rate: 0,
            issued_shares: 0,
        },
    ];
    const turnoverRateData = useTurnoverRateResource({
        date: intraday.format('YYYY-MM-DD'),
        days: 5,
        threshold: 0.1,
        volume_gte: 1e3,
        volume_lte: 1e10,
        amount_gte: 1e7,
        amount_lte: 1e10,
    }).data ?? turnoverEmptyData;
    const turnoverSymbol = turnoverRateData.map(s => s.symbol);
    useEffect(() => {
        signalrStore2.addQuote(turnoverSymbol);
        return () => {
            signalrStore2.removeQuote(turnoverSymbol);
        };
    }, [JSON.stringify(turnoverSymbol)]);
    //投信--------------------------------------------------------
    const big2EmptyData = [
        {
            symbol: '',
            open: 0,
            high: 0,
            low: 0,
            close: 0,
            volume: 0,
            amount: 0,
            total_volume: 0,
            diff_volume: 0,
            diff_volume_percentage: 0,
        },
    ];
    const [big2Date, setBig3Date] = useState(day0);
    const trustData = useBig3ConsecutiveResource({
        date: big2Date.format('YYYY-MM-DD'),
        days: 1,
        action: state.big2ActiveType,
        institution: 'trust',
        amount_gte: 1e7,
        volume_gte: 1e3,
    }).data ?? big2EmptyData;
    const trustSymbol = trustData.map(s => s.symbol);
    const foreignerData = useBig3ConsecutiveResource({
        date: big2Date.format('YYYY-MM-DD'),
        days: 1,
        action: state.big2ActiveType,
        institution: 'foreigner',
        amount_gte: 1e7,
        volume_gte: 1e3,
    }).data ?? big2EmptyData;
    const foreignerSymbol = foreignerData.map(s => s.symbol);
    useEffect(() => {
        if (trustSymbol.length === 0) {
            setBig3Date(day1);
        }
        edwin_store.big3Date = big2Date;
    }, [day1, big2Date, trustSymbol.length]);
    useEffect(() => {
        signalrStore2.addQuote(trustSymbol);
        return () => {
            signalrStore2.removeQuote(trustSymbol);
        };
    }, [JSON.stringify(trustSymbol)]);
    useEffect(() => {
        signalrStore2.addQuote(foreignerSymbol);
        return () => {
            signalrStore2.removeQuote(foreignerSymbol);
        };
    }, [JSON.stringify(foreignerSymbol)]);
    //自選股--------------------------------------------------------
    const { state: watchListState } = useSymbolWatchList(edwinGroupName);
    const big3StockEmptyData = [
        {
            date: '',
            symbol: '',
            foreigner_diff_volume: 0,
            trust_diff_volume: 0,
            dealer_diff_volume: 0,
            dealer_hedge_diff_volume: 0,
            big3_diff_volume: 0,
            foreigner_diff_value: 0,
            trust_diff_value: 0,
            dealer_diff_value: 0,
            dealer_hedge_diff_value: 0,
            big3_diff_value: 0,
        },
    ];
    const big3Data = apirc.marketDataAPI.big3StockData.useSWR({
        date: day0.format('YYYY-MM-DD'),
        symbols: watchListState.symbolListArray.join(','),
    }).data ?? big3StockEmptyData;
    //
    //選股 跟據後端即時選股的商品變化重新訂閱
    const screenerValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(screenerSymbol ?? ['']));
    //熱門股 跟據後端即時熱門股的商品變化重新訂閱
    const popularValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(popularSymbol ?? ['']));
    //投信買賣超 會根據 買/賣 重新訂閱
    const trustValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(trustSymbol ?? ['']));
    //外資買賣超 會根據 買/賣 重新訂閱
    const foreingerValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(foreignerSymbol ?? ['']));
    //外資與投信買賣超 會根據 買/賣 重新訂閱
    const big2Value = signalrHooks2.useQuotes(useSignalr2QuoteSort(state.big2Symbol ?? ['']));
    //週轉率 跟據後端即時週轉率的商品變化重新訂閱
    const turnoverValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(turnoverSymbol ?? ['']));
    //自選股 跟據firebase自選股商品變化重新訂閱
    const watchlistValue = signalrHooks2.useQuotes(useSignalr2QuoteSort(watchListState.symbolListArray ?? ['']));
    const quoteValue = () => {
        if (state.mode === 'rank') {
            return popularValue;
        }
        if (state.mode === 'watchlist') {
            return watchlistValue;
        }
        if (state.mode === 'big2' && state.big2Type === 'trust') {
            return trustValue;
        }
        if (state.mode === 'big2' && state.big2Type === 'foreigner') {
            return foreingerValue;
        }
        if (state.mode === 'big2' && state.big2Type === 'all') {
            return big2Value;
        }
        if (state.mode === 'screener') {
            return screenerValue;
        }
        if (state.mode === 'turnoverRate') {
            return turnoverValue;
        }
        return [];
    };
    return (<styleds.container>
      <styleds.buttonGroup>
        <Button.Group css={css `
            width: 100%;
            gap: 2px;
          `}>
          <ModeButton mode='screener'>選股</ModeButton>
          <ModeButton mode='rank'>排行</ModeButton>
          <ModeButton mode='big2'>法人</ModeButton>
          <ModeButton mode='turnoverRate'>週轉率</ModeButton>
          <ModeButton mode='watchlist'>自選股</ModeButton>
          {/* <ModeButton mode='industry'>族群</ModeButton> */}
        </Button.Group>
      </styleds.buttonGroup>

      <styleds.listContnet>
        {state.mode === 'screener' && (<ScreenerContent quoteValue={screenerValue} symbols={screenerSymbol}/>)}
        {state.mode === 'watchlist' && (<WatchListContent quoteValue={watchlistValue} data={big3Data}/>)}
        {state.mode === 'rank' && <RankingContent quoteValue={popularValue}/>}
        {state.mode === 'turnoverRate' && (<TurnoverContent quoteValue={turnoverValue} data={turnoverRateData}/>)}
        {state.mode === 'big2' && (<Big2Content quoteTrustValue={trustValue} quoteForeignerValue={foreingerValue} trustData={trustData} foreignerData={foreignerData}/>)}
      </styleds.listContnet>
      <styleds.listStateOverview>
        <ListChangeOverview value={quoteValue()}/>
      </styleds.listStateOverview>
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    background-color: #1d2630;
    border-radius: 6px;
    padding: 4px;
    gap: 4px;
    user-select: none;
  `,
    buttonGroup: styled.div `
    ${fill_horizontal_cross_center};
    height: 40px;
    gap: 8px;
    font-size: 14px;
    border-bottom: 1px solid #343434;
  `,
    listContnet: styled.div `
    ${fill_vertical_cross_center};
    height: calc(100% - 150px);
  `,
    listStateOverview: styled.div `
    width: 100%;
    height: 102px;
    background-color: #2d323c;
    border-radius: 4px;
    padding: 4px;
  `,
};
