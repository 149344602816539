//cms video
export const cmsFuturesaiVideoData = [
    {
        url: 'https://www.youtube.com/watch?v=GgERc2Z_4eE',
        title: ':狂歡的臉:選擇權專家「嘉修哥」來了！:祕密:新手如何有方法的交易選擇權？獲利秘訣是什麼？feat. @user-fw9ls1wx2q',
        thumbnail: 'https://i.ytimg.com/vi/GgERc2Z_4eE/hqdefault.jpg',
        mime: 'video/youtube',
        rawData: {
            title: ':狂歡的臉:選擇權專家「嘉修哥」來了！:祕密:新手如何有方法的交易選擇權？獲利秘訣是什麼？feat. @user-fw9ls1wx2q',
            author_name: '期天大勝',
            author_url: 'https://www.youtube.com/@user-kb6py1el4u',
            type: 'video',
            height: 113,
            width: 200,
            version: '1.0',
            provider_name: 'YouTube',
            provider_url: 'https://www.youtube.com/',
            thumbnail_height: 360,
            thumbnail_width: 480,
            thumbnail_url: 'https://i.ytimg.com/vi/GgERc2Z_4eE/hqdefault.jpg',
            html: '<iframe width="200" height="113" src="https://www.youtube.com/embed/GgERc2Z_4eE?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen title=":狂歡的臉:選擇權專家「嘉修哥」來了！:祕密:新手如何有方法的交易選擇權？獲利秘訣是什麼？feat. @user-fw9ls1wx2q"></iframe>',
        },
    },
];
export const cmsFreemanVideoData = [
    {
        url: 'https://www.youtube.com/v/QAM73kueaPA?version=3',
        title: '做台指還要看權值股?! 自由人如何看出盤面走勢! 2023/11/08(三) 20:00 直播教學',
        thumbnail: 'https://i2.ytimg.com/vi/QAM73kueaPA/hqdefault.jpg',
        mime: 'application/x-shockwave-flash',
        rawData: {
            title: '做台指還要看權值股?! 自由人如何看出盤面走勢! 2023/11/08(三) 20:00 直播教學',
            author_name: '自由人',
            author_url: 'https://www.youtube.com/channel/UCKYsEcghAPJvINpq7wJszOA',
            type: 'application/x-shockwave-flash',
            height: 390,
            width: 640,
            version: '3',
            provider_name: 'YouTube',
            provider_url: 'https://www.youtube.com/',
            thumbnail_height: 360,
            thumbnail_width: 480,
            thumbnail_url: 'https://i2.ytimg.com/vi/QAM73kueaPA/hqdefault.jpg',
            html: 'https://www.youtube.com/v/QAM73kueaPA?version=3',
        },
    },
];
export const cmsUSTVVideoData = [
    {
        url: 'https://www.youtube.com/v/Cp6YtXCLUDE?version=3',
        title: '傳英特爾獲美國防晶片合約 成補貼最大贏家!陸進博會 歐美晶片巨頭熱情未減!中國出口黯淡 紐油跌4% Fed官員再唱"鷹"調｜主播陳韋如｜&#8203;``&#8203;``【oaicite:1】``&#8203;``&#8203;20231108｜非凡財經新聞',
        thumbnail: 'https://i4.ytimg.com/vi/Cp6YtXCLUDE/hqdefault.jpg',
        mime: 'application/x-shockwave-flash',
        rawData: {
            title: '傳英特爾獲美國防晶片合約 成補貼最大贏家!陸進博會 歐美晶片巨頭熱情未減!中國出口黯淡 紐油跌4% Fed官員再唱"鷹"調｜主播陳韋如｜&#8203;``&#8203;``【oaicite:0】``&#8203;``&#8203;20231108｜非凡財經新聞',
            author_name: 'USTV 非凡電視',
            author_url: 'https://www.youtube.com/channel/UCYIVkruUoN04UjV9pkBTswg',
            type: 'application/x-shockwave-flash',
            height: 390,
            width: 640,
            version: '3',
            provider_name: 'YouTube',
            provider_url: 'https://www.youtube.com/',
            thumbnail_height: 360,
            thumbnail_width: 480,
            thumbnail_url: 'https://i4.ytimg.com/vi/Cp6YtXCLUDE/hqdefault.jpg',
            html: 'https://www.youtube.com/v/Cp6YtXCLUDE?version=3',
        },
    },
];
export const cmsYuVideoData = [
    {
        url: 'https://www.youtube.com/v/9ADHF6H2Agw?version=3',
        title: '鋼鐵革命的領航者，這產業中長期需求看增!',
        thumbnail: 'https://i2.ytimg.com/vi/9ADHF6H2Agw/hqdefault.jpg',
        mime: 'application/x-shockwave-flash',
        rawData: {
            title: '鋋鐵革命的領航者，這產業中長期需求看增!',
            author_name: '投資優我罩',
            author_url: 'https://www.youtube.com/channel/UCmBQRXvXkeYVBT8nZykMfWw',
            type: 'application/x-shockwave-flash',
            height: 390,
            width: 640,
            version: '3',
            provider_name: 'YouTube',
            provider_url: 'https://www.youtube.com/',
            thumbnail_height: 360,
            thumbnail_width: 480,
            thumbnail_url: 'https://i2.ytimg.com/vi/9ADHF6H2Agw/hqdefault.jpg',
            html: 'https://www.youtube.com/v/9ADHF6H2Agw?version=3',
        },
    },
];
// You can use the YouTubeVideoData array to display this video information in your React application.
