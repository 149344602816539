import { css } from '@emotion/react';
import { memo } from 'react';
import { fill_horizontal_all_center, fill_horizontal_cross_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { cmsFuturesaiVideoData, cmsFreemanVideoData, cmsUSTVVideoData, cmsYuVideoData, } from './youtubeVideoData';
import YouTube from 'react-youtube';
import { scrollbar2Css } from '~/css/scrollbarCss';
import { proxy, useSnapshot } from 'valtio';
import { useMount } from 'react-use';
//youtuber v3 api key
//AIzaSyC3JHLcbzxI5krYrhNs7zzudMGCNNjhGdc
//AIzaSyDlnN4fwntb902t5hwTw-hSwqGyickmVqQ
//自由人youtube channel id
//UCKYsEcghAPJvINpq7wJszOA
//非凡Morring Call 清單id
//PLSg6_lakxpXE4MVtJY4Og7JKXKdg7O1DP
//EBC東森財經股市24小時直播 video id
//LbS-xQ67fos
const opts = {
    playerVars: {
        autoplay: 0,
    },
};
function extractVideoId(url) {
    const regex = /\/vi\/([^/]+)\//;
    const match = url.match(regex);
    if (match && match[1]) {
        return match[1];
    }
    else {
        return '';
    }
}
export const freemanStore = proxy({
    pageType: 'list',
    currentChannelName: 'freeman',
    currentVideoId: '',
    popularSymbols: [],
    tw50Symbols: [],
    highPriceSymbols: [],
});
const ChannelButton = memo(function ChannelButton(props) {
    const state = useSnapshot(freemanStore);
    const selected = state.currentChannelName === props.channelName;
    const pageType = props.pageType;
    const channelName = props.channelName;
    const videoID = props.videoID;
    return (<span css={css `
        ${classes.column.channelName};
        border-bottom: 1.6px solid ${selected ? '#ff0000' : 'transparent'};
        font-size: ${selected ? '12.6' : '12'}px;
        color: ${selected ? '#f1f1f1' : '#b0b0b0'};
      `} onClick={() => {
            freemanStore.pageType = pageType;
            freemanStore.currentChannelName = channelName;
            if (videoID !== undefined) {
                freemanStore.currentVideoId = videoID;
            }
        }}>
      {props.children}
    </span>);
});
export const VideoContent = memo(function VideoContent() {
    const state = useSnapshot(freemanStore);
    const dataOfChnannelType = () => {
        //假設有多種 作者頻道
        /** 期天 */
        if (state.currentChannelName === 'futuresai') {
            return cmsFuturesaiVideoData;
        }
        /** 自由人 */
        if (state.currentChannelName === 'freeman') {
            return cmsFreemanVideoData;
        }
        /** 投資優我罩 */
        if (state.currentChannelName === 'yu') {
            return cmsYuVideoData;
        }
        /** 非凡 */
        if (state.currentChannelName === 'ustv') {
            return cmsUSTVVideoData;
        }
        return cmsFuturesaiVideoData;
    };
    const defaultVideoID = cmsFreemanVideoData.map(s => extractVideoId(s.thumbnail));
    useMount(() => {
        freemanStore.currentVideoId = defaultVideoID[0];
    });
    return (<div css={classes.container}>
      {/* 左側控制欄 */}
      <div css={classes.column.container}>
        <img src={'./freeman/yt_logo_rgb_dark.png'} css={classes.youtubeIcon}/>
        <ChannelButton pageType='player' channelName='player' videoID={state.currentVideoId}>
          影片播放器
        </ChannelButton>
        <ChannelButton pageType='list' channelName='freeman'>
          自由人
        </ChannelButton>
        <ChannelButton pageType='list' channelName='futuresai'>
          期天大勝
        </ChannelButton>
        <ChannelButton pageType='list' channelName='ustv'>
          非凡Morning Call
        </ChannelButton>
        <ChannelButton pageType='list' channelName='yu'>
          投資優我罩
        </ChannelButton>
        <ChannelButton pageType='player' videoID='LbS-xQ67fos' channelName='ebc'>
          東森財經直播
        </ChannelButton>
      </div>
      {/* 播放器 */}
      {state.pageType === 'player' && (<div css={classes.videoPlayer.container}>
          <YouTube videoId={state.currentVideoId} opts={opts}/>
        </div>)}

      {state.pageType === 'list' && (<div css={classes.videoList.container}>
          {dataOfChnannelType().map(video => (<div css={classes.card.container} onClick={() => {
                    freemanStore.currentVideoId = extractVideoId(video.thumbnail);
                    freemanStore.pageType = 'player';
                    freemanStore.currentChannelName = 'player';
                }} key={video.url}>
              <img css={classes.card.images} src={video.thumbnail}/>
              <span css={classes.card.titleContainer}>
                <span css={classes.card.title}> {video.title}</span>
              </span>
            </div>))}
        </div>)}
    </div>);
});
const classes = {
    container: css `
    display: grid;
    grid-template-columns: 108px 1fr;
    width: 100%;
    height: 100%;
  `,
    youtubeIcon: css `
    width: 90px;
    padding: 6px 0;
    margin-bottom: 6px;
  `,
    column: {
        container: css `
      ${fill_vertical_cross_center};
      gap: 4px;
      padding: 2px;
      background-color: #212020;
      border-radius: 4px;
    `,
        channelName: css `
      ${fill_horizontal_cross_center};
      height: 24px;
      font-weight: 500;
      cursor: pointer;
      padding: 0 2px;
      &:hover {
        border-bottom: 1.6px solid #787878;
      }
      transition: 0.3s;
    `,
    },
    videoPlayer: {
        container: css `
      width: 100%;
      height: 100%;
      & > * {
        width: 100%;
        height: 100%;
      }
      & > * > iframe {
        width: 100%;
        height: 100%;
      }
    `,
    },
    videoList: {
        container: css `
      ${fill_vertical_cross_center};
      ${scrollbar2Css};
      gap: 8px;
      padding: 2px;
    `,
    },
    card: {
        container: css `
      ${fill_horizontal_all_center};
      display: grid;
      grid-template-columns: 100px 1fr;
      width: 100%;
      max-height: 60px;
      border-radius: 4px;
      flex-shrink: 0;
      cursor: pointer;
    `,
        images: css `
      width: 100%;
      height: 100%;
      max-height: 60px;
    `,
        titleContainer: css `
      ${fill_vertical_cross_center};
      max-height: 60px;
    `,
        title: css `
      width: 100%;
      height: auto;
      font-size: 13px;
      line-height: 16px;
      font-weight: 500;
      color: #f1f1f1;
      padding: 0 2px;
      overflow: hidden;
    `,
        description: css `
      width: 100%;
      height: 100%;
      font-size: 12px;
      line-height: 14px;
      color: #cccccc;
      white-space: pre-wrap;
      overflow: hidden;
      padding: 0 2px;
      transform: scale(0.94);
      margin-left: -6%;
    `,
        date: css `
      ${fill_vertical_cross_center};
      align-items: start;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: #f1f1f1;
      & > * {
        border-radius: 6px;
        background-color: #252525;
        transform: scale(0.8);
        padding: 1px 4px;
      }
    `,
    },
};
// const apiKey = 'AIzaSyC3JHLcbzxI5krYrhNs7zzudMGCNNjhGdc'
// const getChannelDataUrl = `https://www.googleapis.com/youtube/v3/search?key=${apiKey}&channelId=UCKYsEcghAPJvINpq7wJszOA&part=snippet&type=video&order=date`
// const apiListDataUrl = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=10&playlistId=PLSg6_lakxpXE4MVtJY4Og7JKXKdg7O1DP&key=${apiKey}`
// const opts = {
//   playerVars: {
//     autoplay: 0,
//   },
// }
// useEffect(() => {
//   axios
//     .get(apiUrl)
//     .then(response => {
//       setVideos(response.data.items)
//       if (response.data.items.length > 0) {
//         setCurrentVideo(response.data.items[0])
//       }
//     })
//     .catch(error => {
//       console.error('Error fetching data:', error)
//     })
// }, [])
