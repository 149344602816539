import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect, useMemo, useState } from 'react';
import { fill_horizontal_cross_center, fill_vertical_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { Button } from '@mantine/core';
import { edwin_store } from './edwin_store';
import { useSignalr2QuoteSort } from '~/modules/symbolPlatform/useSignalr2QuoteSort';
import { signalrHooks2 } from '~/modules/SDK/Signalr/signalrHooks2';
import { fr_instrument } from '~/modules/SDK/FrInstrument/FrInstrument';
import { scrollbar2Css } from '~/css/scrollbarCss';
import IntradayTrendChart from '~/modules/trendChart';
import { useSnapshot } from 'valtio';
import { store } from '../heineken_template/_private/store';
import TitleSymbolQuote from '../../modules/investment-consultant/symbolList/body/SymbolQuoteTitle';
import { PopularIndustryContent } from './component/industrySelecter/PopularIndustryContent';
import KbarSymbolQuote from './component/quote/KbarSymbolQuote';
import { edwinGroupName } from './component/WatchListGroupName';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { WatchListAddButton } from './component/WatchListAddButton';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
const ModeButton = memo(function ModeButton(props) {
    const state = useSnapshot(edwin_store).industryType;
    return (<Button variant='gradient' size='sm' gradient={state === props.type
            ? { from: '#ecb04d', to: '#f4bd50', deg: 90 }
            : { from: '#2b333e', to: '#2b333e', deg: 90 }} css={css `
          color: ${state === props.type ? '#252525' : ''};
          width: 100%;
          height: 28px;
          padding: 0;
        `} onClick={() => (edwin_store.industryType = props.type)}>
        {props.children}
      </Button>);
});
export const Edwin_Sidebar2 = memo(function Edwin_Sidebar2() {
    //要顯示: 相關產業 / 熱門產業
    const state = useSnapshot(edwin_store).industryType;
    const charting = useSnapshot(store).charting;
    /** 主產業 */
    const symbolCategory = fr_instrument.getCategoryBySymbol(charting.symbol);
    /** 細產業 */
    const symbolIndustry = useMemo(() => {
        return fr_instrument.getIndustryBySymbol(charting.symbol)?.map(s => s) ?? ['尚無提供'];
    }, [charting.symbol]);
    //----------------------------------------------------------------
    //通過用戶點擊的細產業去秀出相關符合的商品
    const [showList, setShowList] = useState('');
    const list = fr_instrument.getSymbolsOfIndustry(showList) ?? [];
    useEffect(() => {
        signalrStore2.addQuote(list ?? ['TSEA']);
        return () => {
            signalrStore2.removeQuote(list ?? ['TSEA']);
        };
    }, [JSON.stringify(list)]);
    const value = signalrHooks2.useQuotes(useSignalr2QuoteSort(list, 2));
    //目前圖表商品代號
    const symbolName = charting.symbol;
    //人類可讀商品名稱
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    //首次載入 將細產業第一項代為預設顯示項目
    //或是更換商品時要重新載入
    useEffect(() => {
        setShowList(symbolIndustry[0]);
    }, [symbolIndustry, symbolName]);
    return (<styleds.container>
      <styleds.symbol.container>
        <styleds.symbol.titleQuote>
          <TitleSymbolQuote.Simple symbol={charting.symbol}/>
          <WatchListAddButton groupName={edwinGroupName}/>
        </styleds.symbol.titleQuote>
        <styleds.symbol.trendChart>
          <IntradayTrendChart symbol={charting.symbol} ticksSize={12} ticksHeight={20} priceTicksMargin={-14} priceTicksSize={12}/>
        </styleds.symbol.trendChart>
        <styleds.symbol.industryHeader>
          <styleds.symbol.industryHeaderItem>主產業</styleds.symbol.industryHeaderItem>
          <styleds.symbol.industryHeaderItem>細產業</styleds.symbol.industryHeaderItem>
        </styleds.symbol.industryHeader>
        <styleds.symbol.industryBody>
          <styleds.symbol.industryItemTitle>
            {symbolCategory ?? '無'}
          </styleds.symbol.industryItemTitle>

          {symbolIndustry[0] === '無' && (<styleds.symbol.industryItemTitle>無</styleds.symbol.industryItemTitle>)}
          {symbolIndustry[0] !== '無' && (<styleds.symbol.industryItem>
              {symbolIndustry.reduce((acc, s, index) => {
                const name = s.replace(/\(.*?\)/g, '');
                const parts = name.split(':');
                const result = parts.length > 1 ? parts[1] : parts[0];
                const selected = showList === s;
                const element = (<span css={css `
                        ${industryItemCss}
                        text-decoration: ${selected ? 'underline' : 'none'};
                      `} key={index} onClick={() => {
                        setShowList(s);
                        edwin_store.industryType = 'user';
                    }}>
                      {result}
                    </span>);
                if (index === 0) {
                    return [element];
                }
                else {
                    return [...acc, '、 ', element];
                }
            }, ['尚無提供']) ?? '尚無提供'}
            </styleds.symbol.industryItem>)}
        </styleds.symbol.industryBody>
      </styleds.symbol.container>

      <styleds.modeContent>
        <Button.Group>
          <ModeButton type='default'>熱門細產業</ModeButton>
          <ModeButton type='user'>{dictionary[symbolName]}相關細產業</ModeButton>
        </Button.Group>
      </styleds.modeContent>
      <styleds.list.body>
        {state === 'user' && <IndustrySymbolList quoteValue={value}/>}
        {state === 'default' && <PopularIndustryContent />}
      </styleds.list.body>
    </styleds.container>);
});
//需要抽出
/** 相關細產業 */
const IndustrySymbolList = memo(function IndustrySymbolList(props) {
    const value = props.quoteValue;
    return (<div css={css `
          ${fill_vertical_cross_center};
          display: grid;
          grid-template-rows: 36px calc(100% - 40px);
          gap: 4px;
        `}>
        <KbarSymbolQuote.header sortGroup={2}/>
        <div css={css `
            ${scrollbar2Css};
            ${fill_vertical_cross_center};
            gap: 2px;
          `}>
          {value.map((quote, index) => {
            return (<KbarSymbolQuote.body groupName={edwinGroupName} key={index} quote={quote}/>);
        })}
        </div>
      </div>);
});
const industryItemCss = css `
  cursor: pointer;
  width: auto;
  height: 18px;
  &:hover {
    text-decoration: underline;
  }
`;
const styleds = {
    container: styled.div `
    ${fill_vertical_cross_center};
    padding: 4px;
    gap: 4px;
    border-radius: 6px;
    background-color: #1d2630;
  `,
    symbol: {
        container: styled.div `
      ${fill_vertical_cross_center};
      height: 250px;
      gap: 2px;
    `,
        titleQuote: styled.div `
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: calc(100% - 72px) 72px;
      gap: 8px;
      width: 100%;
      height: 28px;
    `,
        trendChart: styled.div `
      width: 100%;
      height: 136px;
      background-color: #272c35;
      border-radius: 4px;
      padding: 4px;
    `,
        industryHeader: styled.div `
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: calc(36% - 2px) calc(64% - 2px);
      height: 20px;
      font-size: 12px;
      gap: 4px;
    `,
        industryHeaderItem: styled.div `
      width: 100%;
      height: 100%;
      background-color: #3f4551;
      border-radius: 4px;
      padding: 0 2px;
    `,
        industryBody: styled.div `
      ${fill_horizontal_cross_center};
      display: grid;
      grid-template-columns: calc(36% - 2px) calc(64% - 2px);
      height: 60px;
      font-size: 12px;
      gap: 4px;
    `,
        industryItem: styled.div `
      gap: 4px;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 4px;
        background-color: #555;
      }
      &::-webkit-scrollbar-thumb {
        width: 4px;
        border-radius: 5px;
        background-color: #ccc;
      }
      flex-wrap: wrap;
      background-color: #2c323d;
      border-radius: 4px;
      padding: 2px;
      flex-shrink: 0;
    `,
        industryItemTitle: styled.div `
      ${fill_vertical_all_center};
      background-color: #2c323d;
      border-radius: 4px;
      padding: 2px;
      flex-shrink: 0;
    `,
    },
    modeContent: styled.div `
    width: 100%;
    height: 30px;
  `,
    list: {
        body: styled.div `
      ${fill_vertical_cross_center};
      height: calc(100% - 288px);
    `,
    },
};
// const formattedIndustryData = (data: string[]) => {
//   const result = data
//     .map(item => {
//       const replace = item?.replace(/\(.*?\)/g, '')
//       const parts = replace.split(':')
//       return parts.length > 1 ? parts[1] : null
//     })
//     .filter(Boolean)
//   return result.filter(item => !item?.includes('_'))
// }
